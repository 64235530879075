<template>
	<div :id="id"></div>
</template>
<script>
	import echarts from "echarts"; // 引入echarts

	export default {
		data() {
			return {
				chart: null,
				echart_opt: {
					legend: {
						orient: "horizontal",
						bottom: 10,
					},

					selectedMode: "single",
					color: [
						"#007ae1", //2
						"#78c97d", //6 #A2E5B9
						"#F97394", //10

						"#9f86ff", //1
						"#FEDB67", //7
						"#3DA2D6", //3
						"#3CC5E7", //4

						"#6DE1E1", //5

						"#FD9F83", //8

						"#DE64AD", //10
						"#E492D0", //11
						"#E5BDF2", //12
					], //series没有设置颜色会依次循环取色,
					// color: [
					// 	"#9f86ff", //1
					// 	"#007ae1", //2
					// 	"#3DA2D6", //3
					// 	"#3CC5E7", //4
					// 	"#78c97d", //6 #A2E5B9
					// 	"#F97394", //10

					// 	"#FEDB67", //7

					// 	"#6DE1E1", //5

					// 	"#FD9F83", //8

					// 	"#DE64AD", //10
					// 	"#E492D0", //11
					// 	"#E5BDF2", //12
					// ], //series没有设置颜色会依次循环取色,
				},
			};
		},
		props: {
			id: {
				type: String,
				default: "",
			},
			options: {
				type: Object,
				default: () => {},
			},
		},
		watch: {
			options: {
				handler(options) {
					if (!this.chart && options) {
						this.echartInit();
					} else {
						// echarts.dispose();
						// this.echartInit();
						Object.assign(this.echart_opt, this.options);
						this.chart.setOption(this.echart_opt, true);
					}
				},
				deep: true,
			},
		},
		mounted() {
			this.echartInit();
		},
		methods: {
			echartInit() {
				// 绘制图表
				Object.assign(this.echart_opt, this.options);
				this.chart = echarts.init(document.getElementById(this.id));
				this.chart.setOption(this.echart_opt, true);
				setTimeout(() => {
					this.chart.resize();
				}, 200);
			},
		},
	};
</script>